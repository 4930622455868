<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{
  account: mastodon.v1.Account,
  number?: number
}>()
const tags = computed(() => [...(props.account.tags?.length ? props.account.tags : [])].slice(0, props.number ?? 5))
</script>

<template>
  <ul v-if="tags.length" class="account-tags tags">
    <li v-for="tag in tags" :key="tag.id">
      <NuxtLink :to="{ name: 'tags-id-profiles', params: {id: tag.id} }">
        {{ tag.name }}
      </NuxtLink>
    </li>
  </ul>
</template>

<style lang='scss'>
.account-tags.tags {
  padding-top: 0;
  li {
    margin: 0;
    background-color:
      color-mix(in lab, var(--color-bg), var(--color-panel-minor) 20%);
    padding-inline: var(--padding-small);
    border-radius: calc(var(--corner-radius)* 0.75);
    a { color: var(--color-text); }
  }
}
</style>
