<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{ account: mastodon.v1.Account }>()
const theme = useTheme(computed(() => props.account.theme))
</script>

<template>
  <div class="account-card-link account-card theme-bg theme-text theme" :style="theme">
    <NuxtLink :to="getAccountRoute(account)" class="">
      <div class="account-card theme-bg theme">
        <header>
          <AccountAvatar :account="account" sizes="96" />
        </header>
        <div class="title">
          <AccountDisplayName :account="account" class="theme-text-accent" />
          <AccountStats :account="account" short />
        </div>
      </div>
    </NuxtLink>
    <AccountTags :account="account" />
  </div>
</template>

<style lang='scss'>
.account-card-link {
  --badge-size: calc(var(--base-size) * 1.8);

  border-radius: var(--corner-radius);
  border: 1px solid #8a8a8a47;
  text-decoration: none;
  container-type: inline-size;
  padding: var(--padding-base);
  overflow: clip;

  display: grid;
  grid-template-rows: 1fr auto;
  gap: var(--padding-small);

  &:focus,
  &:hover {
    text-decoration: none;
  }

  a, a:hover, a:focus {
    text-decoration: none;
  }

  .account-tags {
    align-items: flex-end;
    // margin-bottom: var(--padding-base);
    overflow: auto;
    overscroll-behavior: contain;
  }

  .account-avatar {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
  }

  .account-display-name {
    font-size: var(--font-size-1);
  }

  header {
    display: flex;
    margin-bottom: var(--padding-base);
    align-items: center;
  }

  .timeline-box {
    position: relative;
    max-height: 100%;
    overflow: hidden;

    .theme-container-grid {
      padding-top: 0;
    }
  }

  .status-card {
    border-radius: calc(var(--corner-radius) * 0.8);
    width: 100%;
    overflow: hidden;
    container-type: inline-size;

    .status-mini {
      --scale: 0.45;
      position: relative;
      padding: var(--content-padding);
      color: black;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        z-index: 1000;
        width: 100%;
        height: 45%;
        background: linear-gradient(transparent, var(--color-bg-rgb));
        background: linear-gradient(transparent, var(--color-bg-p3));
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    .text {
      font-size: 3cqi;
      padding: 2cqi;
    }
  }
}
</style>
