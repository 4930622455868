<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{
  url: string
  channel: string
}>()
const { url, channel } = toRefs(props)

const { stream } = useStreaming<{ type: string, account: mastodon.v1.Account }>(channel)
const { items, status, error, loadNext } = await usePaginatorFetch<mastodon.v1.Account>(
  url,
  'id',
  computed(() => ({ type: stream.value?.type, payload: stream.value?.account })),
)
const endAnchor = useEndAnchor(loadNext)
</script>

<template>
  <CommonPaginator :items="items" class="grid" v-bind="{ keyProp: 'id', pending: status === 'pending', error }">
    <template #default="{ item }">
      <AccountCard :account="item" />
    </template>
    <template #done>
      <div ref="endAnchor" />
    </template>
  </CommonPaginator>
</template>
